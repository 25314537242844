//Placeholder
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

@function stripUnit($value) {
  @return $value / ($value * 0 + 1);
}
@function toRem($pxValue) {
  @return #{stripUnit($pxValue) / stripUnit($main-fs)}rem;
}
