.works-carousel {
  flex-direction: column;
  width: auto;

  .owl-stage-outer {
    overflow: visible;
  }
  .owl-nav {
    color: #fff;
    justify-content: center;
    margin-top: toRem(70);
    @media #{$max991} {
      justify-content: center;
      margin: toRem(24) 0 0;
    }

    .owl-prev,
    .owl-next {
      border: 1px solid #fff !important;

      &:hover {
        border-color: rgba(#fff,.8) !important;
      }
    }
  }
  .work {
    background: #fff;
    margin: 0 auto;
    width: toRem(240);
    @media #{$max1229} {
      width: toRem(230);
    }

    .work-preview {
      height: toRem(280);
      overflow: hidden;
      position: relative;

      .project-logo {
        left: toRem(16);
        position: absolute;
        top: toRem(18);
        width: toRem(56);
      }
    }
    .work-content {
      padding: toRem(16);

      .work-title {
        margin-bottom: 0;
        text-align: center;
      }
      .work-desc {
        font-size: toRem(12);
        line-height: 1.333;
      }
    }
  }
}
