.page-box {
  .app-section {
    padding: toRem(70) 0;
    @media #{$max767} {
      padding: toRem(48) 0;
    }

    .section-header {
      padding-bottom: toRem(54);
      @media #{$max767} {
        padding-bottom: toRem(32);
      }

      .section-title {
        margin-bottom: toRem(16);
      }
      .section-desc {
        font-size: toRem(18);
        margin-bottom: toRem(16);
        @media #{$max767} {
          font-size: toRem(16);
        }
      }
    }
  }
}
.container {
  @media #{$max767} {
    padding-left: toRem(16);
    padding-right: toRem(16);
  }
}
