@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.preloader {
  align-items: center;
  background: #5CB4D4;
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  .icon {
    animation: rotating 2s linear infinite;
  }

  body.loaded & {
    opacity: 0;
    transition:
      .2s opacity .2s ease-in-out,
      0s visibility .4s ease-in-out;
    visibility: hidden;
  }
}
