.app-section.form-section {
  .project-form {
    margin: 0 auto;
    max-width: toRem(380);

    .form-floating {
      margin-bottom: toRem(16);
    }
  }
}
