.app-section.feedbacks-section {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;

  .bg-image {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 50%;
    z-index: -1;
    @media #{$max767} {
      margin: 0 auto;
      max-width: toRem(540);
      padding: 0 toRem(16) toRem(24);
      position: relative;
      width: 100%;
      z-index: initial;
    }
    @media #{$max575} {
      max-width: 100%;
      width: 100%;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .feedbacks-carousel-wrap {
    @media #{$min768} {
      padding-left: toRem(38);
    }

    .feedbacks-carousel {
      .owl-nav {
        margin-top: toRem(83);
        @media #{$max767} {
          justify-content: center;
          margin-top: toRem(24);
        }
      }
    }
  }
}
