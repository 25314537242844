.btn {
  border-radius: 4px;
  font-size: toRem(13);
  font-weight: 600;
  line-height: 1.3;
  padding: toRem(13) toRem(33);
  
  &.btn-info {
    color: #fff;
  }
  &.btn-white {

  }
}
