.owl-carousel {
  .owl-nav {
    align-items: center;
    color: #333;
    display: flex;
    margin: 0 toRem(-8);
    @media #{$max767} {
      margin: 0 toRem(-4);
    }

    .owl-prev,
    .owl-next {
      border: 1px solid #BDBDBD !important;
      border-radius: 50%;
      display: block;
      font-size: 0 !important;
      height: toRem(56);
      margin: 0 toRem(8);
      position: relative;
      transition:
        border .2s ease-in-out,
        opacity .2s ease-in-out;
      width: toRem(56);
      @media #{$max767} {
        height: toRem(36);
        margin: 0 toRem(4);
        width: toRem(36);
      }

      &:after,
      &:before {
        content: '';
        left: 50%;
        position: absolute;
        top: 50%;
      }
      &:after {
        border-top: toRem(2) solid;
        border-radius: toRem(2);
        height: 0;
        margin: toRem(-1) 0 0 toRem(-11);
        width: toRem(22);
        @media #{$max767} {
          margin: toRem(-1) 0 0 toRem(-9);
          width: toRem(18);
        }
      }
      &:before {
        border-top: toRem(2) solid;
        border-right: toRem(2) solid;
        height: toRem(8);
        margin: toRem(-4) 0 0 toRem(2);
        transform: rotate(45deg);
        width: toRem(8);
        @media #{$max767} {
          height: toRem(6);
          margin: toRem(-3) 0 0 toRem(3);
          width: toRem(6);
        }
      }
      &:hover {
        border-color: #333 !important;
      }
      &.disabled {
        opacity: .6;
      }
    }
    .owl-prev {
      &:before {
        margin-left: toRem(-11);
        transform: rotate(-135deg);
        @media #{$max767} {
          margin-left: toRem(-9);
        }
      }
    }
  }
}
