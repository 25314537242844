.form-control {
  border: 1px solid #E0EBF5;
  border-radius: toRem(4);
  color: #424D63;
  font-size: toRem(15);
  font-weight: 600;
  line-height: 1.6;
  padding: toRem(9);

  &:focus {
    box-shadow: none;
  }
}
textarea.form-control {
  min-height: toRem(100);
}
.form-floating {
  > .form-control,
  > .form-select {
    height: toRem(60);
    padding: toRem(25) toRem(9) toRem(9);
  }
  > label {
    color: #7987A4;
    font-size: toRem(15);
    padding: toRem(25) toRem(9) toRem(9);
  }
  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-select {
    ~ label {
      opacity: 1;
      transform: scale(.8) translateY(toRem(-20)) translateX(0);
    }
  }
}
.form-with-icon {
  .icon {
    height: toRem(18);
    left: toRem(10);
    position: absolute;
    top: toRem(29);
    width: toRem(18);
  }
  > .form-control,
  > .form-select,
  > label {
    padding-left: toRem(41);
  }
  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-select {
    ~ label {
      transform: scale(.8) translateY(toRem(-20)) translateX(toRem(-32));
    }
  }
}
