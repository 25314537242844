html {
  font-size: $main-fs;
}
body {
  color: $main-color;
  font-size: 1rem;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button, input, optgroup, select, textarea {
  font: inherit;
}
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 700;
}
h1, .h1 {
  font-size: toRem(48);
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: toRem(48);
  @media #{$max767} {
    font-size: toRem(35);
    margin-bottom: toRem(32);
  }
}
h2, .h2 {
  font-size: toRem(40);
  @media #{$max767} {
    font-size: toRem(28);
  }
}
h3, .h3 {
  font-size: toRem(26);
  @media #{$max767} {
    font-size: toRem(21);
  }
}
h4, .h4 {
  font-size: toRem(22);
  @media #{$max767} {
    font-size: toRem(19);
  }
}
h5, .h5 {
  font-size: toRem(18);
  @media #{$max767} {
    font-size: toRem(16);
  }
}
h6, .h6 {

}
p,
ul,
ol,
pre,
table,
blockquote,
.blockquote {
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }
}
ul ul, ol ol, ul ol, ol ul {
  margin-top: 0;
  margin-bottom: 0;
}
hr, .hr {

}
sub, sup {
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
a {

  &:hover,
  &:focus {

  }
}
img {
  height: auto;
  max-width: 100%;
}
button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
textarea {
  box-shadow: none;
}
