.feedback {
  @media #{$max767} {
    text-align: center;
  }
  .feedback-title {
    margin-bottom: toRem(70);
    @media #{$max767} {
      margin-bottom: toRem(24);
    }
  }
  .feedback-text {
    color: #4F4F4F;
    font-size: toRem(18);
    font-style: italic;
    line-height: 1.388888888888889;
    margin-bottom: toRem(24);
    padding: toRem(34) 0 toRem(18);
    position: relative;
    @media #{$max767} {
      font-size: toRem(14);
      margin-bottom: toRem(0);
      padding: toRem(26) 0;
    }

    &:before,
    &:after {
      background: url('../img/quote.svg') 50% 50% no-repeat;
      height: toRem(22);
      position: absolute;
      width: toRem(22);
    }
    &:before {
      content: '';
      left: 0;
      top: 0;
      transform: rotate(180deg);
    }
    &:after {
      bottom: 0;
      content: '';
      right: 0;
    }
  }
  .feedback-author {
    align-items: center;
    display: flex;
    @media #{$max767} {
      justify-content: center;
    }

    .feedback-logo {
      margin-right: toRem(16);
      width: toRem(56);
    }
    .feedback-company {
      font-size: toRem(18);
      font-weight: 700;
    }
  }
}
