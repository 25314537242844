.feature {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 0 toRem(32);
  text-align: center;
  width: 100%;

  .feature-icon {
    align-items: center;
    border: 1px solid #CFE1EA;
    border-radius: toRem(25);
    display: flex;
    justify-content: center;
    height: toRem(144);
    margin: 0 0 toRem(33);
    padding: toRem(10);
    position: relative;
    width: toRem(144);
    @media #{$max767} {
      border-radius: toRem(20);
      height: toRem(90);
      margin: 0 0 toRem(24);
      width: toRem(90);
    }

    .line {
      border-top: toRem(1) solid #ECF0F2;
      left: 0;
      margin-top: toRem(-0.5);
      position: absolute;
      right: 0;
      top: 50%;
      z-index: -1;

      &.vertical {
        transform: rotate(90deg);
      }
      &.rotate-45 {
        left: toRem(-20);
        right: toRem(-20);
        transform: rotate(45deg);
        @media #{$max767} {
          left: toRem(-10);
          right: toRem(-10);
        }
      }
      &.rotate--45 {
        left: toRem(-20);
        right: toRem(-20);
        transform: rotate(-45deg);
        @media #{$max767} {
          left: toRem(-10);
          right: toRem(-10);
        }
      }
    }
  }
  .feature-title {
    margin-bottom: toRem(16);
  }
  .feature-text {
    color: #4F4F4F;
    @media #{$max767} {
      font-size: toRem(13);
    }
  }
}
