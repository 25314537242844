.app-section.main-section {
  background: url('../content/main-section-bg.jpg') 50% 50% no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: toRem(50);
  padding-bottom: toRem(50);
  @media #{$max767} {
    padding-top: toRem(30);
    padding-bottom: toRem(32);
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .section-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: toRem(42);
    padding-bottom: toRem(30);
    @media #{$max767} {
      align-items: flex-end;
      flex-grow: 1;
      padding-top: toRem(32);
      padding-bottom: toRem(32);
    }

    .main-info {
      color: #fff;
      margin-right: toRem(20);
      max-width: toRem(510);
      @media #{$max767} {
        margin-right: 0;
        max-width: 100%;
        text-align: center;
      }

      .desc {
        font-size: toRem(16);
        font-weight: 600;
        line-height: 1.4375;
        margin-bottom: toRem(48);
        @media #{$max767} {
          font-size: toRem(15);
          margin-bottom: toRem(32);
        }
      }
    }
    .social-links {
      display: flex;
      flex-direction: column;

      .link {
        display: block;
        height: toRem(32);
        margin: toRem(12) 0;
        text-decoration: none;
        transition: opacity .2s ease-in-out;
        width: toRem(32);

        &.twitter {
          background-image: url('../img/twitter.svg');
        }
        &.facebook {
          background-image: url('../img/facebook.svg');
        }
        &.google-plus {
          background-image: url('../img/google-plus.svg');
        }
        &.linked-in {
          background-image: url('../img/linked-in.svg');
        }
        &:hover {
          opacity: .6;
        }
      }
    }
  }
  .section-footer {
    position: relative;
    @media #{$max767} {
      padding-top: toRem(35);
    }

    &:before {
      background: #fff;
      bottom: 100%;
      content: '';
      height: toRem(134);
      left: 50%;
      margin: 0 0 0 toRem(-1);
      position: absolute;
      width: toRem(2);
      @media #{$max767} {
        height: toRem(35);
        bottom: auto;
        top: 0;
      }
    }
    .slide-down {
      align-items: center;
      background: #fff;
      border-radius: 50%;
      display: flex;
      height: toRem(56);
      justify-content: center;
      margin: 0 auto;
      padding: 0;
      width: toRem(56);

      svg {
        transition: transform .2s ease-in-out;
      }
      &:hover {
        svg {
          transform: translateY(5px);
        }
      }
    }
  }
}
