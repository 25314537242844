.clients {
  display: flex;
  flex-wrap: wrap;
  margin: 0 toRem(-15) toRem(-30);
  @media #{$max767} {
    justify-content: center;
    margin: 0 toRem(-8) toRem(-16);
  }

  .client {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto toRem(30);
    max-width: toRem(178);
    text-align: center;
    @media #{$max767} {
      margin-bottom: toRem(16);
      max-width: toRem(162);
    }

    .client-wrap {
      align-items: center;
      border: 1px solid #CFE1EA;
      border-radius: toRem(25);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: toRem(178);
      margin: 0;
      padding: toRem(14);
      position: relative;
      width: 100%;
      @media #{$max767} {
        border-radius: toRem(22);
        height: toRem(162);
      }

      .line {
        border-top: toRem(1) solid #ECF0F2;
        left: 0;
        margin-top: toRem(-0.5);
        position: absolute;
        right: 0;
        top: 50%;
        z-index: -1;

        &.vertical {
          transform: rotate(90deg);
        }
        &.rotate-45 {
          left: toRem(-34);
          right: toRem(-34);
          transform: rotate(45deg);
          @media #{$max767} {
            left: toRem(-24);
            right: toRem(-24);
          }
        }
        &.rotate--45 {
          left: toRem(-34);
          right: toRem(-34);
          transform: rotate(-45deg);
          @media #{$max767} {
            left: toRem(-24);
            right: toRem(-24);
          }
        }
      }
      .empty {
        height: toRem(16);
      }
      .client-name {
        font-size: toRem(14);
        font-weight: 700;
        margin: 0;
      }
    }
  }
}
