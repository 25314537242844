@import './mixins';

$main-fs: 14px;
$main-color: #333;

//Media
$max575 : only screen and (max-width: 575px);
$min576 : only screen and (min-width: 576px);
$max767 : only screen and (max-width: 767px);
$min768 : only screen and (min-width: 768px);
$max991 : only screen and (max-width: 991px);
$min992 : only screen and (min-width: 992px);
$max1099 : only screen and (max-width: 1099px);
$min1100 : only screen and (min-width: 1100px);
$max1229 : only screen and (max-width: 1229px);
$min1230 : only screen and (min-width: 1230px);
